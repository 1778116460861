@mixin hover_state{
	&:hover, &:active, &:focus{
		@content;
	}
}

@mixin circle($bg, $border, $color, $size){
	background: $bg;
	border: solid 1px $border;
	border-radius: $size;
	color: $color;
	height: $size;
	width: $size;
	display: table;
	margin: 0 auto;
	text-align: center;
	overflow: hidden;

	@include hover_state {
		background: $bg;
		border-color: $border;
		color: $color;
	}
	span{
		display: table-cell;
		vertical-align: middle;
		position: relative;
	}

}

@mixin helvetica-neue-light {
	font-family: "HelveticaNeueLight", "HelveticaNeue-Light", "Helvetica Neue Light", "HelveticaNeue", "Helvetica Neue", 'TeXGyreHerosRegular', "Helvetica", "Tahoma", "Geneva", "Arial", sans-serif;
	font-weight: 300;
	font-stretch:normal;
}

@mixin helvetica-neue-regular {
	font-family: "HelveticaNeue", "Helvetica Neue", "HelveticaNeueRoman", "HelveticaNeue-Roman", "Helvetica Neue Roman", 'TeXGyreHerosRegular', "Helvetica", "Tahoma", "Geneva", "Arial", sans-serif;
	font-weight: 400;
	font-stretch: normal;
}

@mixin helvetica-neue-medium {
	font-family: "HelveticaNeueMedium", "HelveticaNeue-Medium", "Helvetica Neue Medium", "HelveticaNeue", "Helvetica Neue", 'TeXGyreHerosRegular', "Helvetica", "Tahoma", "Geneva", "Arial", sans-serif;
	font-weight: 500;
	font-stretch: normal;
}


@mixin media_query($size) {
	@if $size == tablet {
		@media (min-width: 768px) {
			@content;
		}
	}
	@else if $size == desktop{
		@media (min-width: 992px){
			@content;
		}
	}
	@else if $size == widescreen{
		@media (min-width: 1200px){
			@content;
		}
	}
}

/**!
	* Mixin name 
	*   fontfamily
	* Description
	*   Mixin that sets fontfamily whole package
	* Parameters
	*   $family:      for font family
	*   $size:        for font size
	*   $lineHeight:  for font line-height
	*   $weight:      for font weight
*/
@mixin fontfamily($family: "Helvetica Neue", $size: 1em, $lineHeight: 1em, $weight: 400){
	font-family: $family, Helvetica, Arial, sans-serif;
	font-size: $size;
	line-height: $lineHeight;
	font-weight: $weight;
}

/**!
	* Mixin name 
	*   fontsize
	* Description
	*   Mixin that sets fontsize package
	* Parameters
	*   $family:      for font family
	*   $size:        for font size
	*   $lineHeight:  for font line-height
	*   $weight:      for font weight
*/
@mixin fontsize ($size: 1em, $lineHeight: 1em, $weight: 400) {
	font-size: $size;
	line-height: $lineHeight;
	font-weight: $weight;
}

/**!
	* Mixin name 
	*   roundedcornersmixin
	* Description
	*   Mixin that makes borders with rounded corners for all browsers
	* Parameters
	*   $radius:      for border radious
*/
@mixin roundedcornersmixin($radius: 10px){
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	border-radius: $radius;
}

/**!
	* Mixin name 
	*   box-shadow
	* Description
	*   Mixin that makes box shadow for all browsers
	* Parameters
	*   $style:      for box shadow style
*/
@mixin box-shadow($style: 5px rgba(0, 0, 0, 0.5)){
	-webkit-box-shadow: $style;
	box-shadow: $style;
}

/**!
	* Mixin name 
	*   transition
	* Description
	*   Mixin that makes transitions for all browsers
	* Parameters
	*   $style:      for transition style
*/
@mixin transition($style){
	-webkit-transition: $style;
	transition: $style;
}
/**!
	* Mixin name 
	*   transform-origin
	* Description
	*   Mixin that sets transform-origin for all browsers
	* Parameters
	*   $string:      for transform-origin style
*/
@mixin transform-origin($string){
	transform-origin: $string;
	-ms-transform-origin: $string;
	-webkit-transform-origin: $string;
}

/**!
	* Mixin name 
	*   animation
	* Description
	*   Mixin that sets animation for all browsers
	* Parameters
	*   $string:      for animation style
*/
@mixin animation($string){
	-webkit-animation: $string;
	-moz-animation:    $string;
	-o-animation:      $string;
	animation:         $string;
}

/**!
	* Mixin name 
	*   stopanimationlastframe
	* Description
	*   Mixin that makes animation stop at the last frame of it
*/
@mixin stopanimationlastframe(){
	animation-timing-function: linear;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
	-webkit-animation-iteration-count: 1;
	-webkit-animation-timing-function: linear;
	-webkit-animation-fill-mode: forwards;
}

/**!
	* Mixin name 
	*   transform
	* Description
	*   Mixin that sets transform for all browsers
	* Parameters
	*   $string:      for transformation
*/
@mixin transform($string){
	-webkit-transform:   $string;
	-moz-transform:    $string;
	-ms-transform:     $string;
	-o-transform:      $string;
	transform:       $string;
}

/**!
	* Mixin name 
	*   scale
	* Description
	*   Mixin that sets scale for all browsers
	* Parameters
	*   $factor:      for scale
*/
@mixin scale ($factor) {
	-webkit-transform:   scale($factor);
	-moz-transform:    scale($factor);
	-ms-transform:     scale($factor);
	-o-transform:      scale($factor);
}
/**!
	* Mixin name 
	*   rotate
	* Description
	*   Mixin that rotates element for all browsers
	* Parameters
	*   $deg:      degrees of rotation
*/
@mixin rotate ($deg) {
	-webkit-transform: rotate($deg);
	-moz-transform:    rotate($deg);
	-ms-transform:     rotate($deg);
	-o-transform:      rotate($deg);
}
/**!
	* Mixin name 
	*   multi-col
	* Description
	*   Mixin that splits a text to multiple columns for all browsers
	* Parameters
	*   $col:      columns number
	*   $wid:      columns width
	*   $gap:      gap between columns
*/
@mixin multi-col($col, $wid, $gap) {
	-webkit-column-count: $col;
	-moz-column-count: $col;
	column-count: $col;
	-webkit-column-width: $wid;
	-moz-column-width: $wid;
	column-width: $wid;
	-webkit-column-gap: $gap;
	-moz-column-gap: $gap;
	column-gap: $gap;
}
/**!
	* Mixin name 
	*   displayFlex
	* Description
	*   Mixin that makes display flex for containerelement for all browsers
*/
@mixin displayFlex(){
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
}
/**!
	* Mixin name 
	*   flexDirection
	* Description
	*   Mixin that gives flex direction for a flex container element
	* Parameters
	*   $string:      flex-basis style
*/
@mixin flexDirection($flexflow: row wrap){
	flex-flow: $flexflow;
}


/**!
	* Mixin name 
	*   flex
	* Description
	*   Mixin that make a flex for item element for all browsers (It is the shorthand for flex-grow, flex-shrink and flex-basis combined)
	* Parameters
	*   $string:      style of flex
*/
@mixin flex($string: none){
	-webkit-flex: $string;
	-moz-flex: $string;
	-ms-flex: $string;
	flex: $string;
}
/**!
	* Mixin name 
	*   flex-basis
	* Description
	*   Mixin that make the flex-basis of a flex item for all browsers
	* Parameters
	*   $string:      flex-basis style
*/
@mixin flex-basis($string: auto){
	-webkit-flex-basis: $string;
	-moz-flex-basis: $string;
	-ms-flex-basis: $string;
	flex-basis: $string;
}
/**!
	* Mixin name 
	*   vertical-align
	* Description
	*   Mixin that gives verical align middle for non td elements
	* Parameters
	*   $position:      relative or absolute
*/
@mixin vertical-align($position: relative){ 
	display: block;
	position: $position;
	top: 50%;
	@include transform(translateY(-50%));
}
/**!
	* Mixin name 
	*   bottom-align
	* Description
	*   Mixin that gives verical align bottom for non td elements
	* Parameters
	*   $position:      relative or absolute
*/
@mixin bottom-align($position: relative) {
	display: block;
	position: $position;
	top: 100%;
	@include transform(translateY(-100%));
}
/**!
	* Mixin name 
	*   arrow
	* Description
	*   Mixin that creates an arrow
	* Parameters
	*   $color:      color of arrow
	*   $width:      width of arrow
*/
@mixin arrow($color: #FFFFFF, $width: 3px){ 
	border: solid $color;
	border-width: 0 $width $width 0;
	display: inline-block;
	padding: $width;
}
/**!
	* Mixin name 
	*   triangleDown
	* Description
	*   Mixin that creates an triangle with arrow down
	* Parameters
	*   $color:      color of triangle
	*   $width:      width of triangle
*/    
@mixin triangleDown($color: #FFFFFF, $width: 3px) {
	width: 0;
	height: 0;
	border-left: $width solid transparent;
	border-right: $width solid transparent;
	border-top: $width solid $color;
}
/**!
	* Mixin name 
	*   appearance
	* Description
	*   Mixin that changes appearance
	* Parameters
	*   $appearance: appearance
*/
@mixin appearance($appearance: none) {
	-moz-appearance: $appearance;
	-webkit-appearance: $appearance;
	appearance: $appearance;
}
/**!
	* Mixin name 
	*   showLines
	* Description
	*   Mixin that show specific number of lines
	* Parameters
	*   $lines:      lines to be shown
	*   $font-size:  font-size to fix explorer problems with max-height
*/
@mixin showLines($lines: 2, $font-size: 1em) {
	$new-height:  $lines * 1.1;
	display: -webkit-box;
	-webkit-line-clamp: $lines;
	-webkit-box-orient: vertical;
	overflow: hidden;
	max-height: $new-height * $font-size;
}
