.splash{
	$splash-font-size: 4.5rem;

	height: 100vh;
	text-align: center;
	margin: 0;
	box-sizing: border-box;

	.message{
		font-size: $splash-font-size;
		line-height: $splash-font-size;
		text-shadow: rgba(0, 0, 0, 0.5) 0 0 1rem;
		text-transform: uppercase;
		font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	}
	.fa-spinner{
		text-align: center;
		display: inline-block;
		font-size: $splash-font-size;
		margin-top: calc(50vh - calc($splash-font-size / 2));
	}
}