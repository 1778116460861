* {
	box-sizing: border-box;
}
*::before, *::after {
	box-sizing: border-box;
}
html,
body{
	scroll-behavior: smooth;
	font-size: $font-size!important;
	overflow-x: hidden;
	background-color: $ittLandingBg;
}
html {
	height: 100%;
	-ms-overflow-style: scrollbar;
}
body{
	margin: 0;
	@include helvetica-neue-regular;
	@include transition(transform 1s linear);
	overflow: hidden;
	color: $authTextColor;
}
a, button, li, input, textarea {
	@include transition(all 0.3s ease 0s);
}
a:focus,
button:focus,
input:focus,
textarea:focus{
	outline: none;
}
input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
	background-color: transparent;
}
p {
	margin: 0 0 0.625rem;
}
.displayNone{
	display: none!important;
}
.img-responsive{
	display: block;
	width: inherit;
	max-width: 100%;
	max-height: 100%;
	height: auto;
}
.clearboth{
	clear:both;
}
.arrow-down {
	@include transform(rotate(45deg));
}
.arrow-up {
	@include transform(rotate(225deg));
}
.sr-only {
	border: 0 none;
	clip: rect(0px, 0px, 0px, 0px);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}
.centered{
	text-align: center!important;
}
.videoWrapper{
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 25px;
	height: 0;
	width: 100%;

	video{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		outline: none;
	}
}

.disabled-link {
	pointer-events: none;
	cursor: default;
	opacity: 0.6;
}
.blurry-text{
	color: $ittPortalGreyC!important;
	text-shadow: 0 0 5px rgba(0,0,0,0.5);
}
.btn-primary {
	color: #fff;
	background-color: $ittBlue;
	border-color: $ittBlue;
}
.btn-orange {
	border-color: $ittOrange;
	background: $ittOrange;
}
#waitLoader{
	$spinSize: 4.6rem;
	display: none;
	position: fixed;
	z-index: 100000;
	width: 100%;
	height: 100%;
	text-align: center;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.8) !important;
	&.active{
		display: block;
	}
	.fa-spin {
		position: absolute;
		top: calc(50% - #{$spinSize});
		left: calc(50% - #{$spinSize});
		color: lightblue;
		font-size: #{2 * $spinSize};
	}
}