.authentication{
  $authentication-first-break-point: 930px;
  $authentication-second-break-point: 630px;
  $border-padding: 1.85rem;

  display: grid;
  grid-gap: 0;
  min-height: 100vh;
  overflow-y: auto;
  height: 100vh;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: $header-height auto;
  grid-template-areas:
		"header header"
		"content sidebar";
  @media(max-width: $authentication-first-break-point){
    grid-template-columns: 1fr 1fr;
  }
  @media(max-width: $authentication-second-break-point){
    grid-template-columns: 100%;
    grid-template-areas:
			"header"
			"content"
			"sidebar";
  }

  header{
    background: #FFFFFF;
    box-shadow: 0px 0.313rem 0.313rem $ittPortalGreyD;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "logo main-menu lang-profile-container";
    @media(max-width: $header-break-point){
      grid-template-areas: "logo main-menu lang-profile-container";
      grid-template-columns: 1fr 1fr;
    }
    a {
      color: $ittBlue;
      font-weight: 700;
      margin: 0 1rem;
    }
    #selected-lang-container {
      color: $ittPortalGrey6;
    }
    #portal-logo img{
      margin-left: 0;
      padding-left: 0;
    }
  }
  .content {
    grid-area: content;
    position: relative;
    padding: 1.25rem 2rem 0;
    align-self: center;
    justify-self: center;
    min-height: auto;
    max-width: 400px;

    @media(max-width: $authentication-second-break-point) {
      border-right: none;
      padding: 5.25rem 2rem 0 2rem;
    }
    h1{
      color: $ittBlue;
      text-transform: uppercase;
      padding-bottom: 0.3rem;
      margin: 0 auto 1.46rem;
      border-bottom: 1px solid $ittBlue;
      font-weight: 400;
      font-size: 1.5rem;
      display: inline-block;
    }
    h2 {
      color: $ittBlue;
      border-bottom: 1px solid $ittBlue;
      padding: 0.625rem;
      display: block;
      text-transform: uppercase;
      text-align: center;
      margin: 0 auto 0.625rem;
      font-size: 1.5rem;
      width: fit-content;
    }
    .below-btn-link{
      text-align: center;
      margin: 0 0 2.3rem;
      a {
        color: $ittPortalGrey6;
        text-decoration: underline;
        &:hover{
          color: $ittPortalGrey6;
          text-decoration: underline;
        }
      }
    }


    .form__group{
      input{
        border: none;
        border-bottom: 1px solid #000000;
        height: 1.5rem;
        background-color: transparent;
        font-size: 0.9rem;
        &[type="checkbox"]{
          height: 1rem;
          & + span{
            line-height: 1.3rem;
          }
        }
      }
      button[type="submit"]{
        width: 100%;
        padding: 0.625rem 0;
        margin-bottom: 1rem;
        font-size: 1.3rem;
        &:focus{
          outline: none;
          outline-offset: 0;
          border-color: $ittBlue;
        }
      }
    }
    #back-to-signin{
      text-decoration: underline;
    }
    p.forgot-password{
      text-align: center;
      margin: 0;
      a{
        text-decoration: underline;
      }
    }
    .btn-blue,
    .btn-orange{
      width: 100%;
      padding: 0.625rem 0;
      margin-bottom: 50px;
      font-size: 1.3rem;
      color: #fff;
    }
    .authentication-button-container{
      position: relative;
      width: 100%;
      padding: $border-padding 0;
      border-top: 1px solid $ittPortalGrey;
      text-align: center;
      .in-line-text{
        position: absolute;
        max-width: 210px;
        width: 100%;
        padding: 0.625rem;
        top: -1.375rem;
        left: 0;
        right: 0;
        margin: 0 auto;
        background-color: $ittLandingBg;
      }
      .authentication-button{
        width: 100%;
        max-width: 150px;
        margin: 0.625rem auto;
        padding: 0.313rem;
        display: block;
        color: #333;
        background-color: #e6e6e6;
        cursor: pointer;
        border: 1px solid $ittPortalGreyA;
        @include transition(all 0.3s ease-in);
        @include roundedcornersmixin(0.313rem);
        &:hover{
          text-decoration: none;
          @include box-shadow(0 0.313rem 0.938rem rgba(0,0,0,0.5));
        }
      }
    }
  }

  .sidebar {
    grid-area: sidebar;
    text-align: center;
    max-width: 600px;
    font-size: 0.9rem;
    line-height: 1.5rem;
    justify-self: stretch;
    align-self: center;
    border-left: 1px solid $ittPortalGrey;
    padding: 1.25rem 2rem 0;
    max-width: 600px;

    @media(max-width: $authentication-second-break-point){
      padding: 1.25rem 1.25rem 2.5rem;
    }
    .i-alert-pc{
      margin: auto;
      max-width: 300px;
    }
    h2{
      font-size: 1.8rem;
      margin-top: 0;
      color: $ittPortalGrey6;
      font-weight: 400;
    }

    .more-info {
      font-size: 1rem;
      margin-bottom: 1rem;
    }
    .browserSupport {
      @media(max-width: $mobile-break-point){
        margin-bottom: 1rem
      }

      table{
        text-align: center;
        margin: 0px auto;
        td{
          padding: 0.125rem 0.313rem;
        }
      }
    }
  }
}