main{
	position: relative;
}
.portal{    
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 0;
	min-height: 100vh;
	grid-template-rows: $header-height auto;
	grid-template-areas:
		"header"
		"content";

	.content {
		grid-area: content;
	    text-align: left;
	    padding: 0;
	    align-self: start;
	    justify-self: start;
		min-height: calc(100vh - #{$header-height});
		width: 100%;

		.portalContent{
			$portalContentPadding: 1rem;
		    padding: $portalContentPadding;
		    min-height: calc(100vh - #{$header-height});
		    height: 100%;
		    background: $ittPortalBg;
		}
	}
}
.message-error{
	color: #a94442;
	background-color: #f2dede;
	border-color: #ebccd1;
	display: inline-block;
	padding: 1.25rem;
	margin-bottom: 1rem;
}