.form{
  .form__group {
    margin-bottom: 1rem;
    .form__error{
      color: $ittPortalRedDark;
      font-size: 0.9rem;
    }
    .form__field{
      display: flex;
      flex-flow: column-reverse;
      position: relative;
      &:after{
        content: "";
        height: 1px;
        width: 80px;
        bottom: 0;
        left: 0;
        position: absolute;
        z-index: 2;
      }
      &.after-none:after{
        display: none;
      }

      label, input {
        transition: all 0.2s;
        touch-action: manipulation;
      }

      input[type=text],
      input[type=number],
      input[type=email],
      input[type=password],
      input[type=url],
      input[type=tel],
      input[type="submit"],
      select,
      textarea{
        margin: 0;
        font-weight: 300;
        font-size: 0.9rem;
        line-height: 1rem;
        border-bottom: 1px solid #000;
        font-family: inherit;
        -webkit-appearance: none;
        border-radius: 0;
        padding: 0;
        cursor: text;
        &:focus {
          outline: 0;
          border-bottom: 1px solid $ittPortalGrey6;
        }
        &:-webkit-autofill + label,
        &:placeholder-shown + label {
          cursor: text;
          max-width: 66.66%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          transform-origin: left bottom;
          transform: translate(0, 1.6rem) scale(1.1);
        }
        &:focus::-webkit-input-placeholder {
          opacity: 1;
        }
        &:not(:placeholder-shown) + label,
        &:focus + label {
          transform: translate(0, 0) scale(1);
          cursor: pointer;
        }
      }
      textarea{
        height: 160px;
        padding: 0.5em 0;
      }

      label {
        font-weight: 300;
        font-size: 0.9rem;
        line-height: 1em;
        letter-spacing: 0.05rem;
        .required{
          color: $ittPortalRedDark;
        }
      }
      ::-webkit-input-placeholder {
        opacity: 0;
        transition: inherit;
      }
    }
  }
  input[type="submit"],
  button{
    margin-top: 1rem;
    font-size: 1rem;
    &:focus{
      outline: none;
      outline-offset: 0;
    }
  }
  input[type="checkbox"]{
    margin: 0 0.5rem 0 0;
    padding: 0;
    height: 1rem;
  }
}