header{
	$header-name-width: 90px;

	grid-area: header;
	background-color: $headerColor;
	display: grid;
	position: fixed;
	z-index: $header-z-index;
    width: 100%;
    top: 0px;    
	grid-gap: 0;
	grid-template-rows: $header-height;
	grid-template-columns: 2fr 8fr minmax(220px, 1fr);
	grid-template-areas:
		"logo main-menu lang-profile-container";
	@media(max-width: $header-break-point){
		grid-template-areas: "logo lang-profile-container main-menu hamburger"
							 "mobileMenu mobileMenu mobileMenu mobileMenu";
		grid-template-columns: 1fr minmax(#{$header-name-width*2}, 1fr) 40px $header-height;
	}	

	.authentication &{
		background: #FFFFFF;
		box-shadow: 0px 5px 5px $ittPortalGreyD;
		grid-template-columns: 1fr 1fr;
		grid-template-areas: "logo main-menu lang-profile-container";
		@media(max-width: $header-break-point){
			grid-template-areas: "logo main-menu lang-profile-container";
			grid-template-columns: 1fr 1fr;
		}
		a {
			color: $ittBlue;
			font-weight: 700;
			margin: 0 1rem;
		}
		#selected-lang-container {
			color: $ittPortalGrey6;
		}
		#portal-logo img{
			margin-left: 0;
			padding-left: 0;
		}
	}
	#portal-logo {
		grid-area: logo;
		align-self: center;
		max-width: 100%;

		img {
			max-height: $header-height;
			width: auto;
			padding-left: 0.625rem;
			max-width: 100%;
			height: auto;
		}
	}

	#main-menu {
		grid-area: main-menu;
		text-align: right;
		padding-right: 0.3125rem;
		align-self: center;

		ul.main-menu-ul {
			display: inline-block;
			padding: 0;
		    margin: 0;
		    width: auto;
			@media(max-width: $header-break-point){
				display: none;
			}

			li {
				padding: 0;
				display: inline-block;
				list-style: none;

				a {
					font-size: 0.6875rem;
				    background: #4a5b63;
				    color: #dfe5ea;
				    padding: 0.3125rem 0.75rem;
				    margin: 0.9375rem 0.3125rem;
				    line-height: 0.6875rem;
				    width: 124px;
				    height: 20px;
				    text-align: center;
				    display: inline-block;

				    &.active,
				    &:hover{
						background: #dfe5ea;
						color: #4a5b63;
						font-weight: bold;
						text-decoration: none;
				    }
				    i{
				    	float: left;
				    	&:after{
				    		content: "";
				    		clear: both;
				    	}
				    }
				}
			}
		}
		.notifications {
			$notifications-list-width: 270px;
            $notifications-list-arrow: 10px;
			display: inline-block;
            padding: 0 0.625rem;
            cursor: pointer;
            color: $ittPortalGrey;
            position: relative;
            .fa-bell{
            	&:not(.active){
                	color: $ittPortalBlue;
            	}
              	&.ringing{
                	@include animation(bell-ringing 1.5s ease);
              	}
            }
            .counter {
				position: absolute;
				@include circle($ittPortalRedDark, $ittPortalRedDark, $ittPortalGrey, 0);
				top: -0.5rem;
				right: -0.3125rem;
				visibility: hidden;
				display: inline-block!important;
				font-size: 0.7em;
				@include transition(all 0.5s);
				&.active {
					$counter-width: 0.9375rem;
					@include circle($ittPortalRedDark, $ittPortalRedDark, $ittPortalGrey, $counter-width);
					min-width: $counter-width;
					width: auto;
					visibility: visible;
					margin-right: 0.3125rem;
				}
            }
            #notifications-list-container{
				position: absolute;
				width: $notifications-list-width;
				top: calc(#{$header-height} - 1rem + 3px);
				left: calc(-1 * $notifications-list-width / 2 + 18px);
				overflow: hidden;
				z-index: 2 ;
				padding-top: $notifications-list-arrow;
				@include transition(height 0.5s ease-in);
				@include roundedcornersmixin(5px);
				@media(max-width: $header-break-point){
					left: calc(-2 * $notifications-list-width / 3);
				}
              	&:after {
					content: '';
					position: absolute;
					left: 0;
					right: 0;
					margin: 0 auto;
					top: $notifications-list-arrow;
					width: 0;
					height: 0;
					border-left: $notifications-list-arrow solid transparent;
					border-right: $notifications-list-arrow solid transparent;
					border-bottom: $notifications-list-arrow solid $ittPortalBlueDark;
					clear: both;
					z-index: 2;
					@include transition(top 0.25s ease-in);

					@media(max-width: $header-break-point){
					    right: 62px;
					    left: auto;
					}
				}
              	&.active{
					//height: $notifications-list-width + 5 * $notifications-list-arrow !important
					&:after{
						top: 0 !important;
					}
					ul#notifications-list{
						max-height: $notifications-list-width !important;
					}
					#all-notifications{
						display: block;
						padding: 10px;
						border: 1px solid rgba(170, 170, 170, 0.4);
					}
					.notifications-ul-container{
						border: 1px solid rgba(170, 170, 170, 0.4)!important;
					}
				}
            	.notifications-ul-container{
					@include roundedcornersmixin(5px 5px 0px 0px);
					border: none;
				}


				#all-notifications{
					font-size: 0.9em;
					background-color: $ittPortalBlueDark;
					position: relative;
					bottom: 0;
					width: 100%;
					z-index: 4;
					display: none;
					text-align: center;
					a {
						color: $ittPortalLeftNav;
						font-size: 0.95em;
					}
				}
				ul#notifications-list {
					max-height: 0;
					position: relative;
					margin: 0;
					padding: 0;
					background-color: $ittPortalLeftNav;
					color: $ittPortalBlueDark;
					width: 100%;
					list-style: none;
					z-index: 3;
					overflow-y: auto;
					text-align: left;
					@include transition(all 0.5s ease-in);

					li{
						border-bottom: 1px solid rgba(170, 170, 170, 0.4);
						font-size: 0.73em;
						padding: 5px;         
						width: 100%;
						display: flex!important;
						flex-direction: row;

						&:last-of-type{
							border-bottom: none;
						}

						$icons-width: 30px;
						.message-image{
							padding: 0;
							text-align: center;
							width: $icons-width;
							font-size: 1.5em;
						}
	                  	i {
	                    	font-size: 24px;
	                  	}
	                  	img{
	                    	@extend .img-responsive;
	                  	}

						.message-content{
							width: calc(100% - #{$icons-width});
							padding-left: 5px;
						}
						.message-text{
							@include showLines(2, 1.65em);
						}

						.message-title{
							font-weight: 700;
							font-size: 1.30em;
						}
						.message-date{
							font-size: 0.9em;
						}
					}
				}
			}
		}
		
	}
	#lang-profile-container {
		grid-area: lang-profile-container;
		text-align: right;
		border-left: 1px solid #FFFFFF;

		@media(max-width: $header-break-point){
			border-left: none;
		}
		.flags{
			display: inline-block;
		}
		.account {
			display: inline-block;

			.headerProfile {
				cursor: pointer;
				border-left: none;
				background: transparent;
				padding:0 0;
				margin: 0 0.625rem 0 0;
				width: auto;
				height: $header-height;
				font-weight: 700;
				font-size: 0.6875rem;
			    line-height: 0.6875rem;
			    color: $ittPortalGrey;
			    @media(max-width: $header-break-point){
					display: none;
				}

				&#headerProfileMobile{
				    display: none;
				    margin-right: 0;
				    @media(max-width: $header-break-point){
						display: block;
					}	
					> span {
						margin-right: 0;
						max-width: 100px;
					}		
					> i.fa{
						visibility: hidden;
					}	
				}

				.headerProfileImg{
					$pictureWidth: 24px;
					@include circle($ittPortalGrey, $ittPortalBlue, $ittPortalBlue, $pictureWidth);
					display: inline;
					width: $pictureWidth;
					height: $pictureWidth;
					margin: 0 calc($pictureWidth/2);
					float: left;
					cursor: pointer;
				}
				> span {
					margin-right: 0.625rem;
					float: left;
					text-align: center;
					cursor: pointer;
					max-width: $header-name-width;
				}
				.headerProfileTitle{
					font-weight: normal;
					font-size: 0.625rem;
					margin-top: 0.25rem;
					display: block;
				}
				> span.no_title{
					margin-top: 0.375rem;
				}

				> i.fa{
					margin-top: 0.375rem;
					cursor: pointer;
				}
			}

	        #headerProfileMenu {
	        	position: absolute;
				top: $header-height;
				overflow: hidden;
				right: 0;
				font-size: 0.75rem;
				padding: 0;
				min-width: 0;
				width: 125px;
				@include transition(height 0.25s ease-in);

				&.active{
					ul {
						max-height: 200px !important;
						border-bottom: 1px solid $ittPortalGreyLight2;
					}
				}
				ul {
					max-height: 0;
					position: relative;
					padding: 0;
					margin: 0;
					border-left: 1px solid $ittPortalGreyLight2;
					@include transition(all 0.5s ease-in);

					li {
						list-style: none;
						width: 100%;

						a {
							background: #FFF;
							color: $ittPortalGrey3;
							padding: 0.3125rem 0.625rem;
							border-top: 1px solid $ittPortalGreyLight2;
							border-right: 1px solid $ittPortalGreyLight2;
							width: 100%;
							margin: 0;
							display: block;
							text-align: left;

							@include hover_state {
								background: $ittPortalGreyC;
								text-decoration: none;
							}
						}
					}
				}
			}
		}
	}

	#mobileMenu{
		grid-area: mobileMenu;
		display: none;
		top: -100%;
		@include transition(top 0.25s ease-in);
		@media(max-width: $header-break-point){
			display: block;
		}
		&.active{
			top: 0;
			ul{
				max-height: 500px;
				border-bottom: 1px solid $ittPortalGreyLight2;
			}
		}
		ul {
			overflow: hidden;
			max-height: 0;
			position: relative;
			padding: 0;
			margin: 0;
			border-left: 1px solid $ittPortalGreyLight2;
			@include transition(all 0.5s ease-in);

			li {
				list-style: none;
				width: 100%;

				a {
					background: #FFF;
					color: $ittPortalGrey3;
					padding: 0.5rem 0.625rem;
					border-top: 1px solid $ittPortalGreyLight2;
					border-right: 1px solid $ittPortalGreyLight2;
					width: 100%;
					margin: 0;
					display: block;
					text-align: left;
					font-weight: 400;
					font-weight: 0.9rem;

					@include hover_state {
						background: $ittPortalGreyC;
						text-decoration: none;
					}
				}
			}
		}
	}

	.hamburger {
		$hamburger-right: 62px;

		grid-area: hamburger;
	    align-self: center;
	    justify-self: center;
	    background-color: transparent;
	    border: 0 none;
	    color: inherit;
	    cursor: pointer;
	    font: inherit;
	    margin: 0;
	    overflow: visible;
	    padding: 15px;
	    text-transform: none;
	    transition-duration: 0.15s;
	    transition-property: opacity, filter;
	    transition-timing-function: linear;
    	z-index: $header-z-index;
		right: 0;
    	display: none;
    	@media(max-width: $header-break-point){
	    	display: block;
	    }
    	@media(max-width: 400px){
	    	right: 5px;
	    }
		&:after{
			content: "MENU";
			position: absolute;
			right: #{-1 * $hamburger-right};
			top: 17px;
			color: #FFFFFF;
			font-weight: 700;

			@media(max-width: $header-break-point){
				display: none;
			}
		}
		&.is-active{
			&:after{
				content: "";
			}
		}
		&.hamburger--slider {
			.hamburger-inner {
			    top: 2px;
			    &:before {
				    top: 10px;
				    transition-duration: 0.15s;
				    transition-property: transform, opacity;
				    transition-timing-function: ease;
				}
				&:after {
				    top: 20px;
				}
			}
			&.is-active .hamburger-inner {
			    transform: translate3d(0px, 10px, 0px) rotate(45deg);

				&:before {
				    opacity: 0;
				    transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0px);
				}
				&:after {
				    transform: translate3d(0px, -20px, 0px) rotate(-90deg);
				}
			}
		}
		.hamburger-box {
		    display: inline-block;
		    height: 24px;
		    position: relative;
		    width: 35px;
		}
		.hamburger-inner {
		    display: block;
		    margin-top: -2px;
		    top: 50%;
		}
		.hamburger-inner,
		.hamburger-inner::after,
		.hamburger-inner::before {
		    background-color: $ittPortalGrey;
		    border-radius: 4px;
		    height: 4px;
		    position: absolute;
		    @include transition(transform 0.15s ease);
		    width: 100%;
		}
		.hamburger-inner::after,
		.hamburger-inner::before {
		    content: "";
		    display: block;
		}
		.hamburger-inner::before {
		    top: -10px;
		}
		.hamburger-inner::after {
		    bottom: -10px;
		}
	}
	
}