$flags-list-width: $header-height;
$flags-list-arrow: 0.625rem;
$flags-icons-width: 1.875rem;

.flag-icon-background {
	background-size: contain;
	background-position: 50%;
	background-repeat: no-repeat;
}
.flag-icon {
	@extend .flag-icon-background;
	position: relative;
	display: inline-block;
	width: 1.333 * 1em;
	line-height: 1em;
	&:before {
		content: '\00a0';
	}

	&.flag-icon-squared{ 
		width: 1em;
	}
}
#unasigned-header-right .flags #flags-list-container{
	left: 0!important;
}
.flags {
	padding: 0.9375rem 0 0.9375rem 0.3125rem;
	cursor: pointer;
	color: $ittPortalGrey;
	position: relative;
	text-transform: uppercase;
	font-size: 0.85rem;
	#unasigned-header-right & {
		color: $ittPortalGrey6;
		margin-right: 0.5em;
	}
	#flags-list-container{
		position: absolute;
		top: $header-height;
		width: $flags-list-width;
		left: calc(-1 * $flags-list-width / 2 + 20px);
		overflow: hidden;
		z-index: 2;
		padding-top: $flags-list-arrow;
		@include transition(height 0.25s ease-in);
		@include roundedcornersmixin(5px);
		
		&:after {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			margin: 0 auto;
			top: $flags-list-arrow;
			width: 0;
			height: 0;
			border-left: none;
			border-right: none;
			border-bottom: none;
			clear: both;
			z-index: 2;
			@include transition(all 0.25s ease-in);
		}
		&.active{
			&:after{
				border-left: $flags-list-arrow solid transparent;
				border-right: $flags-list-arrow solid transparent;
				border-bottom: $flags-list-arrow solid $ittPortalBlueDark;
				top: 0 !important;
			}
			ul#flags-list{
				max-height: 100px !important;
			}
			.flags-ul-container{
				border: 1px solid rgba(170, 170, 170, 0.4)!important;
			}
		}
	}
	.flags-ul-container{
		@include roundedcornersmixin(5px);
		border: none;
	}
	ul#flags-list{
		max-height: 0;
		position: relative;
		margin: 0;
		padding: 0;
		background-color: $ittPortalLeftNav;
		color: $ittPortalBlueDark;
		width: 100%;
		list-style: none;
		z-index: 3;
		overflow-y: hidden;
		@include transition(all 0.5s ease-in);

		li{
			border-bottom: 1px solid rgba(170, 170, 170, 0.4);
			font-size: 0.70em;
			padding: 0.3125rem;          
			width: 100%;
			flex-direction: row;
			text-transform: uppercase;
			display: block !important;
			text-align: center;
			&:last-of-type{
				border-bottom: none;
			}
			&:hover,
			&.active{
				background-color: $ittPortalGreyC;
			}
			span{
				display: inline-block;
				width: $flags-icons-width;
				margin-right: 0.3125rem;   
			}
		}
	}
}
#unasigned-header-right{
	.flags{
		#flags-list-container{			
			&:after{
				top: -10px!important;
			}
			&.active{
				&:after{
					top: 0 !important;
				}
				.flags-ul-container{
					border: none!important;
				}
				ul#flags-list{
					@include roundedcornersmixin(5px);
					border: 1px solid rgba(170, 170, 170, 0.4)!important;
				}
			}
			ul#flags-list {
				background-color: #e6e6e6;
			}
		}
	}
}


@mixin flag-icon($country){
	.flag-icon-#{$country} {
		background-image: url(#{$flag-icon-css-path}#{$flag-icon-rect-path}/#{$country}.svg)
	}
}
