$tablet-break-point: 768px; 
$mobile-break-point: 576px;

$ittPortalLeftNavWidth: 220px;

$font-size: 16px;


$authTextColor: rgba(97, 105, 115, 1);
$btnPrimaryBg: rgba(51, 122, 183, 1);
$headerColor: rgba(37, 55, 65, 1);
$ittBlue: rgba(93, 145, 203, 1);
$ittLandingBg: rgba(241, 246, 250, 1);
$ittOrange: rgba(236, 134, 21, 1);
$ittPortalBg: rgba(249, 250, 252, 1);
$ittPortalLeftNav: rgba(243, 243, 243, 1);
$ittPortalBlue: rgba(74, 91, 99, 1);
$ittPortalBlueDark: rgba(37, 55, 65, 1);
$ittPortalGrey: rgba(223, 229, 234, 1);
$ittPortalGrey3: rgba(51, 51, 51, 1);
$ittPortalGrey6: rgba(102, 102, 102, 1);
$ittPortalGreyA: rgba(170, 170, 170, 1);
$ittPortalGreyC: rgba(204, 204, 204, 1);
$ittPortalGreyD: rgba(221, 221, 221, 1);
$ittPortalGreyE: rgba(238, 238, 238, 1);
$ittPortalGreyLight2: rgba(194, 194, 194, 1);
$ittPortalGreyLight3: rgb(250, 250, 250);
$ittPortalRedDark: rgba(156, 14, 17, 1);


$header-height: 50px;
$header-break-point: 1110px;
$header-z-index: 10;
$menu-item-mobile-height: 35px;