#message-container{
	$bottom: 100px;
	$default-space: 20px;
	position: fixed;
	width: 50%;
	min-width: 280px;
	height: 0;
	bottom: $bottom;
	right: 0;
	z-index: 11000;

	.message{
		position: relative;
		bottom: $bottom;
		right: -100%;
		width: 100%;
		@include transition(right 0.5s linear);
		@include roundedcornersmixin(5px);
		
		&.active{
			right: $default-space;
			+ .message.active{
				margin-top: $default-space;
			}
		}
		&.hide {
			@include transition(all 0.5s linear);
			display: block!important;
			height: 0px!important;
			padding: 0!important;
			opacity: 0;
			margin: 0!important;
			border: 0!important;
			overflow: hidden;
		}
		ul{
			margin-bottom: 0;
			margin-top: 0;
		}
		.message-content{
			display: inline-block;
			padding: $default-space;
		}
		&#message-success{
			color: #3c763d;
			background-color: #dff0d8;
			border-color: #d6e9c6;
		}
		&#message-error{
			color: #a94442;
			background-color: #f2dede;
			border-color: #ebccd1;
		}
		&#message-info{
			color: #245269;
			background-color: #afd9ee;
			border-color: #bce8f1;
		}
		.close-button{
			position: absolute;
			top: 0;
			right: 0;
			background-color: transparent;
			padding: calc($default-space/2);
			cursor: pointer;
			border: 0;
			@include fontsize (0.8em, 0.8em, 700);
		}
	}
}